import React from 'react';
import { useParams } from 'react-router-dom';
import { projects } from '../data/projects';
import HeroHeader from '../components/HeroHeader';
import parse from 'html-react-parser';


const ProjectDetail = () => {
  const { slug } = useParams();
  const project = projects.find((proj) => proj.slug === slug);

  if (!project) {
    return <p>Project not found.</p>;
  }

  return (
    <div>
      <HeroHeader
        h1Text={project.title}
        pText={project.description}
        content={project.meta}
        links={project.details.ctas}
      />


      { project.details.stats &&
        <section className="bg-[#1E5FF1] py-24 sm:py-32">
          <div className="mx-auto flex items-center justify-center ">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <dl
              className={`grid gap-x-20 gap-y-16 text-center ${
                project.details.stats.length === 1
                  ? "grid-cols-1"
                  : project.details.stats.length === 2
                  ? "grid-cols-2"
                  : "grid-cols-3"
              }`}
            >
                {project.details.stats.map((stat, index) => (
                  <div
                    key={index}
                    className={`mx-auto flex flex-col gap-y-4 ${
                      project.details.stats.length === 3
                        && "max-w-48"
                    }`}
                  >
                    <dt className="text-base/7 text-white">{stat.label}</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </section>
      }

      <section className="bg-[#EFF3FB] py-16 px-4">
        <div className="my-24 mx-auto grid grid-cols-1 gap-x-20 gap-y-16 max-w-screen-xl lg:grid-cols-2 lg:gap-y-10">
          <div>{parse(project.details?.content)}</div>
          <div className="flex items-center flex-col relative">
            {project.details.images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.alt}
                className={image.className}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectDetail;
